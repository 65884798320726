





















import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { ViewDataScope } from '@/modules/shared/entities/ViewData'
import { Pageable } from '@/modules/shared/entities/Pagination'

import ViewDataMixin from '@/modules/shared/mixins/ViewDataMixin'

import Headline from '@/modules/shared/components/Headline.vue'
import PublicationList from '../components/PublicationList.vue'

const PublicationModule = namespace('publication')

@Component({
  components: {
    Headline,
    PublicationList,
  },
})
export default class Publications extends Mixins(ViewDataMixin) {
  viewDataScope = ViewDataScope.publication

  @PublicationModule.Action
  private readonly fetchPublications!: (_: {contentType?: string; page?: number}) => Promise<void>

  @PublicationModule.Getter
  private readonly pageable!: Pageable

  mounted() {
    this.fetchPublications({})
  }
}
