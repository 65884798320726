


























import { Component, Prop, Vue } from 'vue-property-decorator'

import Publication from '@/modules/shared/entities/Publication'

import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'

@Component({
  components: {
    PrimaryButton,
  },
})
export default class PublicationItem extends Vue {
  @Prop({ required: true })
  private readonly publication!: Publication
}
